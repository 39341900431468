import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { getSession, formatPhoneNumber, getCheckoutSession, getProducts, getSKUs } from './service'
import CheckoutForm from './CheckoutForm'
import LocationForm from './LocationForm'
import config from './config'
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Dialog, DialogTitle } from '@material-ui/core';
import Checkout from './Checkout';
import OrderDisclaimer from './Disclaimer';
import PhoneVerification from './PhoneVerification'
import {analytics} from './firebase'
import {initiateCheckout} from './tracking'
const phoneNumber = config.app.phoneNumber
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      // display: 'flex',
      // justifyContent: 'space-between',
      // flexFlow: 'row wrap'
      // height: 150px;
      // width: 400px;
      // background: #e7e7e7;
      paddingBottom: 40
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    backButton: {
      // marginRight: theme.spacing(1),
      width: '100%',
      minHeight: 50,
      color: 'white',
      '&:disabled': {
        minHeight: 50,
        color: 'white',
        opacity: '.65'
      },
    },

    nextButton: {
      // marginRight: theme.spacing(1),
      width: '100%',
      minHeight: 50,
      backgroundColor: 'rgba(40,167,69)',
      '&:disabled': {
        color: 'white',
        minHeight: 50,
        opacity: '.65',
        backgroundColor: 'rgba(40,167,69)'
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    flexContainer: {
      // display: 'flex',
      // flexDirection: 'column',
    },
    contentContainer: {
      // margin: 'auto'
    },
    buttonContainer: {
      marginTop: 'auto',
      paddingTop: 24
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export interface MEBCWindow extends Window {
  Stripe?: any;
  recaptchaVerifier?: any;
  confirmationResult?: any;
}

function SessionErrorMessage({ message }: { message: string }) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h3" paragraph>
          Your session has expired.
      </Typography>
        <Typography variant="body1" component="p" paragraph>
          Please start over by texting 'order' to<Link href={`sms:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</Link>
        </Typography>
      </Paper>
    </Grid>

  )
}


export interface CartItem {
  productId?: string
  productName?: string
  skuId?: string
  skuName?: string
  skuPrice?: number
  quantity?: number
  subTotal?: number
  crv?: number
  deposit?: number
}

export interface CartList {
  items: CartItem[],
  deliveryFee: number,
  taxRate: number,
  location?: any,
  instructions?: string
}

export default function HorizontalLinearStepper() {
  const classes = useStyles();
  const { sessionToken = '' } = useParams()
  const [activeStep, setActiveStep] = React.useState(0);
  const [proceed, setProceed] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = useState<any[] | undefined>();
  const [skus, setSKUs] = useState<any[] | undefined>();
  const [cart, setCart] = React.useState<CartList>({
    items: [],
    deliveryFee: config.delivery.fee,
    taxRate: config.delivery.tax
  })
  const [loaded, setLoaded] = React.useState(false)
  const [token, setToken] = React.useState('');
  const [networkError, setNetworkError] = React.useState('');
  const [is21, setIs21] = React.useState<boolean | null>(null)

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const checkout = async () => {
    handleToggle()
    try {
      initiateCheckout(cart.items)
      const sessionResponse = await getCheckoutSession(token, cart);
      const mebcWindow = window as MEBCWindow;
      const stripe = mebcWindow.Stripe(sessionResponse.publicKey);
      stripe.redirectToCheckout({ sessionId: sessionResponse.sessionId });
    } catch (err) {
      console.log(err, err.isAxiosError)
      handleToggle();
      setNetworkError(err.message);
    }

  }

  React.useEffect(() => {
    if (!token && sessionToken) {
      getSession(sessionToken)
        .then((sessionId) => {
          if (sessionId) {
            setToken(sessionId)
          } 
        })
    }

    if (!products && !networkError) {
      getProducts()
        .then((products: any[]) => {
          setProducts(products)
        })
        .catch(() =>
          setNetworkError('Sorry, had trouble fetching the product list')
        )
    }

    if (!skus && !networkError) {
      getSKUs()
        .then((skus: any[]) => {
          setSKUs(skus)
        })
        .catch(() =>
          setNetworkError('Sorry, had trouble fetching the sku list')
        )
    }

    // setToken('test123')
    if (
      // token && 
      products && skus) {
      setLoaded(true)
    }
  }, [token, products, skus, networkError]);

  const steps = ['Where?', 'On Tap', 'Checkout'];

  const trackPageView = (screenName: string) => {
      analytics.setCurrentScreen(screenName)
  }
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        trackPageView('LocationForm')
        return <LocationForm canProceed={(override = true) => setProceed(override)} setCart={setCart} cart={cart} styles={{
          container: {
            minWidth: 350,
            minHeight: 400,
            marginRight: 16,
          }
        }} />
      case 1:
        trackPageView('ProductSelector')
        return <CheckoutForm products={products || []} skus={skus || []} canProceed={() => setProceed(true)} cart={cart} setCart={setCart} />
      case 2:
        trackPageView('Checkout')
        return <Checkout cart={cart} setCart={(cart: CartList) => {
          console.log(cart)
          setCart(cart)
        }} products={products || []} skus={skus || []} canProceed={() => setProceed(true)} />
      default:
        return 'Unknown step';
    }
  }
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      checkout()
    } else {
      setProceed(false);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handlePhoneVerification = (orderId: string) => {
    setToken(orderId)
  }

  if (networkError) {
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={!!networkError}>
        <DialogTitle style={{ backgroundColor: '#f44336', textAlign: 'center' }}>Having A Network Issue</DialogTitle>
        <p className="errorMessage">
          <Button variant="contained" onClick={() => window.location.reload(false)}>
            Try Again
      </Button>
        </p>
      </Dialog>
    )
  }

  if (!loaded) {
    return (
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      </Container>
    )
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className={classes.flexContainer}>
        <div className={classes.contentContainer}>
          {getStepContent(activeStep)}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        {activeStep > 0 &&
          <Button variant="contained" color="secondary" onClick={handleBack} className={classes.backButton}>
            Back
        </Button>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.nextButton}
          disabled={!proceed}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <OrderDisclaimer handleClose={setIs21} isOpen={is21 === null} />
      {!!(!token && activeStep === 1) && <PhoneVerification handleClose={handlePhoneVerification} isOpen={!!(!token && cart.location)} />}
    </div>
  );
}
