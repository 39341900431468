import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReactPixel from 'react-facebook-pixel';
import '../App.css';
import { analytics } from '../firebase';

const useStyles = makeStyles(theme => ({
    formControl: {
        backgroundColor: 'white',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginLeft: '8px',
        marginRight: '8px'
    }
}));

const getTypeFromSKU = (id: string) => {
    if (id.indexOf('mL') > 0) {
        return 'wine'
    } else {
        return 'beer'
    }
}

const getNameFromSKU = (id: string) => {
    if (id.indexOf('growler') > 0) {
        return 'Growler (2 Liter)'
    }
    if (id.indexOf('crowler') > 0) {
        return 'Crowler (1 Liter)'
    }
    if (id.indexOf('fourpack') > 0) {
        return 'Four Pack (16oz cans)'
    }
    if (id.indexOf('sixpack') > 0) {
        return 'Six Pack (12oz cans)'
    }
    if (id.indexOf('twelvepack') > 0) {
        return 'Twelve Pack (12oz cans)'
    }  
    if (id.indexOf('single') > 0) {
        return 'Single (16oz can)'
    } 
    if (id.indexOf('bottle') > 0) {
        return 'Single (22oz bottle)'
    }    
    if (id.indexOf('mL') > 0) {
        return '750 mL bottle'
    }
    if (id.indexOf('15-5-gallon') > 0) {
        return 'Keg (15.5 Gallon)'
    }
    if (id.indexOf('5-gallon') > 0) {
        return 'Keg (5 Gallon)'
    }
}

const getCRVFromSKU = (id: string) => {
    const baseCRV = 5;
    if (id.indexOf('crowler') > 0) {
        return 2 * baseCRV
    }
    if (id.indexOf('fourpack') > 0) {
        return 4 * baseCRV
    }
    if (id.indexOf('sixpack') > 0) {
        return 6 * baseCRV
    }
    if (id.indexOf('twelvepack') > 0) {
        return 12 * baseCRV
    }
    if (id.indexOf('single') > 0) {
        return 1 * baseCRV
    }    
    if (id.indexOf('bottle') > 0) {
        return 1 * baseCRV
    } 
 
    return 0
}

const getDespositFromSKU = (id: string) => {
    if (id.indexOf('gallon') > 0) {
        return 3000;
    } 
    return 0;
}

interface Props {
    skus: any[] | undefined
    values: any,
    setValues: (values: any) => void
}

function FormatForm({ skus, values, setValues }: Props) {
    const classes = useStyles();

    const addFormat = (event: any) => {
        const sku = skus?.find((sku) => sku.id === event.target.value)
        if (sku && sku.id) {
            ReactPixel.track('AddToCart', {
                content_name: values.productName,
                content_type: getTypeFromSKU(sku.id),
                content_id: sku.id,
                value: sku.price,
                currency: 'USD'
            })
            analytics.logEvent('add_to_cart', {
                currency: 'USD',
                value: sku.price
            })
            setValues({
                ...values,
                skuId: sku.id,
                skuName: getNameFromSKU(sku.id) || '',
                skuPrice: sku.price,
                crv: getCRVFromSKU(sku.id),
                deposit: getDespositFromSKU(sku.id)
            })
        }
    }

    const formats = skus?.filter((sku: any) => sku.product === (values && values.productId))
        .map((sku: any, key: number) => <MenuItem key={key} value={sku.id}>{getNameFromSKU(sku.id)} - ${sku.price / 100}</MenuItem>);

    if (!values || !values.productId) {
        return null;
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Format
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={values && values.skuId}
                onChange={addFormat}
                displayEmpty
                className={classes.selectEmpty}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {formats}
            </Select>
        </FormControl>

    )
}

export default FormatForm;
