import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import { phoneNumber, formatPhoneNumber } from './service'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }),
);
function Share() {
  const classes = useStyles();

  const [manual, setManual] = React.useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = `sms:${phoneNumber}`
      setManual(true)
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h3" paragraph>
          Text 'order' to start.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Redirecting you to our online ordering experience...
        </Typography>
        {!manual && <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>}
        {manual && <Typography variant="h5" component="h3" paragraph>
          If you have not redirected automatically, tap <Link href={`sms:${phoneNumber}`}>here</Link> to send a text to start your order.
        </Typography>}
      </Paper>
    </Grid>

  );
}

export default Share;
