import React from 'react'
import TextField from '@material-ui/core/TextField';
import { GoogleMap, StandaloneSearchBox, LoadScript, Marker, DirectionsRenderer, DirectionsService } from '@react-google-maps/api'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import './App.css'
import config from './config'
import { CartList } from './OrderSession';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            margin: theme.spacing(3),
        },
        textField: {
            justifyContent: 'stretch',

            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            minWidth: 350
        },
    }),
);


interface Props {
    styles: {
        container: any
    },
    cart: CartList
    setCart: (cart: CartList) => void
    canProceed: (override?: boolean) => void
}

const Searchbox = ({ styles, cart, setCart, canProceed }: Props) => {
    console.log('render')
    const classes = useStyles();
    const [directions, setDirections] = React.useState<any | undefined>()
    const [searchBox, setSearchBox] = React.useState<any | undefined>();
    const [searchError, setSearchError] = React.useState<string | undefined>()

    const onLoad = (ref: any) => {
        setSearchBox(ref)
    }

    const onPlacesChanged = () => {
        const location = searchBox && searchBox.getPlaces()
        console.log(location)

        if (location) {
            const zipCodeComponent = location[0].address_components.find((component: any) => component.types.includes('postal_code'))
            // const zipCode = zipCodeComponent.short
            if (config.delivery.zipcodes, config.delivery.zipcodes.includes(Number(zipCodeComponent.short_name))) {
                const locationData = {
                    address: location[0].formatted_address,
                    geometry: {
                        lat: location[0].geometry.location.lat(),
                        lng: location[0].geometry.location.lng(),
                    },
                    unitType: ''
                }
                console.log(locationData)
                setCart({
                    ...cart,
                    location: locationData,
                })
                setSearchError(undefined);
            } else {
                setSearchError('Sorry, you are out of our delivery range.')
            }
        } else {
            setSearchError('Please select an address from the dropdown list.')
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value
        setCart({
            ...cart,
            location: {
                ...cart.location,
                unitType: value
            }
        })

        if (value === 'house') {
            canProceed()
        } else {
            canProceed(false);
        }
    };

    const handleUnitNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value
        setCart({
            ...cart,
            location: {
                ...cart.location,
                unitNumber: value
            }
        })
        if (value.length > 0) {
            canProceed()
        } else {
            canProceed(false);
        }
    };

    const directionsCallback = (response: any) => {
        console.log(response)

        if (!directions) {
            if (response !== null) {
                if (response.status === 'OK') {
                    setDirections({
                        response
                    })
                } else {
                    console.log('response: ', response)
                }
            }
        }

    }

    const handleBlur = () => {
        console.log('BLURRIN')
        if (!cart.location || !cart.location.address) {
            setSearchError('Please select an address from the dropdown list.')
        }
    }
    return (
        <div className='map'>
            <div className='map-container'>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey={config.apiKey}
                    libraries={config.maps.libraries}
                >
                    <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                        <TextField
                            id="outlined-full-width"
                            label="Street Address"
                            style={{ margin: 8 }}
                            placeholder="Please enter your delivery address"
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!searchError}
                            helperText={searchError}
                            variant="outlined"
                            onBlur={handleBlur}
                        />
                    </StandaloneSearchBox>
                    {cart.location &&
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">House or Apartment?</FormLabel>
                            <RadioGroup aria-label="quiz" name="quiz" value={cart.location.unitType} onChange={handleRadioChange}>
                                <FormControlLabel value={'house'} control={<Radio />} label="House" />
                                <FormControlLabel value={'apt'} control={<Radio />} label="Apartment" />
                            </RadioGroup>
                        </FormControl>
                    }
                    {cart.location && cart.location.unitType === 'apt' &&
                        <TextField
                            id="outlined-full-width"
                            label="Unit Number?"
                            style={{ margin: 8 }}
                            placeholder="Please enter unit number (optional)"
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            onChange={handleUnitNumberChange}
                        />
                    }
                    {/* <GoogleMap
                        id='searchbox-example'
                        mapContainerStyle={styles?.container}
                        zoom={9}
                        center={config.maps.origin.center}
                    >
                        <Marker
                            position={config.maps.origin.center}
                        />
                        {
                            (
                                cart.location && !directions
                            ) && (
                                <DirectionsService
                                    // required
                                    options={{
                                        destination: cart.location,
                                        origin: config.maps.origin.address,
                                        travelMode: 'DRIVING'
                                    }}
                                    // required
                                    callback={directionsCallback}
                                    // optional
                                    onLoad={directionsService => {
                                        console.log('DirectionsService onLoad directionsService: ', directionsService)
                                    }}
                                    // optional
                                    onUnmount={directionsService => {
                                        console.log('DirectionsService onUnmount directionsService: ', directionsService)
                                    }}
                                />
                            )
                        }
                        {directions && (
                            <DirectionsRenderer
                                // required
                                options={{
                                    directions
                                }}
                                setDirectionsRendererCallback={(response: any) => console.log(response)}
                                // optional
                                onLoad={directionsRenderer => {
                                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                }}
                                // optional
                                onUnmount={directionsRenderer => {
                                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                }}
                            />
                        )
                        }
                    </GoogleMap>
                 */}
                </LoadScript>
            </div>
        </div>
    )
}

export default Searchbox
