import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {trackPurchase} from './tracking'
import { phoneNumber, formatPhoneNumber } from './service'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }),
);
function Success() {
  const classes = useStyles();
  React.useEffect(() => {
    trackPurchase();
  })
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h3" paragraph>
          Thanks for your order!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          An adult over 21 must be present to receive this order. Orders placed by 7:00 P.M. will be delivered “Same Day”, orders received after 7:00 P.M. will be delivered the following delivery day. You order it, we’ll get it to you.
          </Typography>
          <Typography variant="body1" component="p" paragraph>
          All orders will be sanitized using a pure Alcohol Spray above 70% Concentration at the time of delivery. Delivery Vehicles are sanitized, and Drivers will observe proper Social Distancing and use protective measures because we really, truly care.
          </Typography>
          
          <Typography variant="body1" component="p" paragraph>
          Drivers carry no cash and are not able to adjust orders. They do however work very hard and your generosity for their efforts is greatly appreciated. 100% of all tips go to the Driver, like they should.
          </Typography>
          
          <Typography variant="body1" component="p" paragraph>
          Orders of heavy items such as large kegs are contingent upon Drivers ability to safely deliver. Four flights of stairs just isn’t going to cut it. You know who we’re talking to…
          </Typography>
          
          <Typography variant="body1" component="p" paragraph>
          We appreciate you and thank you for supporting Independent Craft during this time. BTW, don’t tell your brothers and sisters, but you’re my favorite…
          </Typography>
          
          <Typography variant="body1" component="p" paragraph>
          Love, Mom 
    <br />
    <br />
          #LoveYourMother
          <br />
          <Link href="https://www.motherearthbrewco.com/">
          Visit our site!
          </Link>
      </Typography>
      </Paper>
    </Grid>
  );
}

export default Success;
