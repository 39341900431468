import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core';
import MuiPhoneInput from 'material-ui-phone-number'
import { makeStyles } from '@material-ui/core/styles';
import { firebase, auth, firestore } from './firebase'
import { MEBCWindow } from './OrderSession';
interface Props {
    handleClose: (orderId: string) => void
    isOpen: boolean
}


// async function captchaVerification(container, callback) {
//     return await new Promise((resolve, reject) => {
//         const verifyCallback = async (response) => {
//             if (response) {
//                 const data = {
//                     token: response,
//                 }
//                 /* const verifyStatus = await fetch('app', {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     redirect: 'manual',
//                     body: JSON.stringify(data)
//                 })
//                 if(verifyStatus) {
//                     captcha.remove()
//                     return resolve(true) 
//                 }
//                 else return reject('Invalid captcha verification.') */ 
//                 return resolve(data) // We need to resolve promise anyway
//             } else {
//                 grecaptcha.reset()
//                 return reject('Invalid captcha verification.')
//             }
//         }
//         const grecaptcha = new firebase.auth.RecaptchaVerifier("recaptcha-container");

//         grecaptcha.render('g-recaptcha', {
//             'sitekey': '6LdFlOQUAAAAABdYGtt_xTVH4hj57fvCrRTgZcST',
//             'theme': 'dark',
//             'callback': verifyCallback
//         })
//     })
// }

export default function PhoneVerifcation({ handleClose, isOpen }: Props) {
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>()
    const [captchaVerification, setCaptchaVerification] = useState<string | undefined>();
    const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | undefined>();
    const [otpCode, setOtpCode] = useState<string | undefined>()
    const mebcWindow = window as MEBCWindow;
    
        
    console.log('Phone Verification Render')
    React.useEffect(() => {
        setTimeout(() => {
            if (!mebcWindow.recaptchaVerifier) {
                try {
                    mebcWindow.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
                        size: 'normal',
                    });
                    // mebcWindow.recaptchaVerifier.clear()
                    mebcWindow.recaptchaVerifier.render().then((thing: any)=> {
                        console.log('rendered', thing)
                    })
                    mebcWindow.recaptchaVerifier.verify().then((response: any) => {
                        setCaptchaVerification(response)
                    })
                } catch(er) {
                    console.log(er)
                }
               
            }
        }, 100)
    })

    function submitPhoneNumberAuth() {
        // We are using the test phone numbers we created before
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber || '', mebcWindow.recaptchaVerifier)
            .then(function (confirmationResult) {
                mebcWindow.confirmationResult = confirmationResult;
                setConfirmationResult(confirmationResult)
            })
            .catch(function (error) {
                console.log('ERRRR', error);
            });
    }

    const handlePhoneNumberSetting = () => {
        submitPhoneNumberAuth()
    }

    const handleCodeCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const code = (event.target as HTMLInputElement).value
        setOtpCode(code)
    }
    
    const handleCodeConfirmation = () => {
        confirmationResult && confirmationResult.confirm(otpCode || '').then(function (result: any) {
            console.log(result.user)
            const orderId = `${Date.now()}`
            const order = {
                messageId: orderId,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                from: phoneNumber
            }
            firestore.collection('orders').doc(orderId).set(order).then(() => {
                handleClose(orderId)
            })

        }).catch(function (error: any) {
            console.log(error)
        });
    }

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
                <DialogTitle id="simple-dialog-title">Just Need One More Thing</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        To complete your order, we need to confirm your mobile phone.  We need this number in case our driver(s) needs to contact you. You will also receive your receipt to this number.
                </DialogContentText>
                </DialogContent>
                {!confirmationResult && <DialogContent>
                    <div id="recaptcha-container" />
                </DialogContent>}
                {!confirmationResult && captchaVerification && <DialogContent>
                    <MuiPhoneInput
                        defaultCountry='us'
                        onlyCountries={['us']}
                        onChange={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
                    />
                    <DialogActions>
                        <Button id="phone-number-submit" onClick={handlePhoneNumberSetting} color="primary">
                            Save
                    </Button>
                    </DialogActions>
                    <div id="recaptcha-container" />
                </DialogContent>}
                {confirmationResult && <DialogContent>
                    <TextField
                        label="authorization code"
                        onChange={handleCodeCapture}
                    />

                    <DialogActions>
                        <Button onClick={handleCodeConfirmation} color="primary">
                            Save
                    </Button>
                    </DialogActions>
                </DialogContent>}
                
            </Dialog>

        </>
    );
}
