import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import '../App.css';

const useStyles = makeStyles(theme => ({
    formControl: {
        backgroundColor: 'white',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginLeft: '8px',
        marginRight: '8px'
    }
}));

interface Props {
    values: any,
}

function SubtotalForm({ values }: Props) {
    const classes = useStyles();

    if (!values || !values.skuId || !values.quantity) {
        return null
    }
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Sub-Total
            </InputLabel>
            <div style={{ paddingTop: '6px' }}>
                <p>${((values && values.subTotal) || 0) / 100}</p>
            </div>
        </FormControl>

    )
}

export default SubtotalForm;
