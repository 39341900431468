import * as firebase from 'firebase';
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
interface FirebaseConfig {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
}

const staging: FirebaseConfig = {
    apiKey: "AIzaSyApfviTz8D49xV3Q_GN8dWi1suTuD7nCcw",
    authDomain: "staging.loveyourmother.beer",
    databaseURL: "https://mebc-staging.firebaseio.com",
    projectId: "mebc-staging",
    storageBucket: "mebc-staging.appspot.com",
    messagingSenderId: "1067544919894",
    appId: "1:1067544919894:web:644c823afa78d53906a0f7",
    measurementId: "G-7V9FHJCM1K"
}

const prod: FirebaseConfig = {  
    "apiKey": "AIzaSyDiLC1soydxGVrX-Zx44h9TYq1hZui7ajc",
    "authDomain": "loveyourmother-prod.firebaseapp.com",
    "databaseURL": "https://loveyourmother-prod.firebaseio.com",
    "projectId": "loveyourmother-prod",
    "storageBucket": "loveyourmother-prod.appspot.com",
    "messagingSenderId": "91942654938",
    "appId": "1:91942654938:web:f59200984e319e781526e3",
    "measurementId": "G-61JW6CQBB9"
}

// let firebaseConfig: FirebaseConfig =  prod
let firebaseConfig: FirebaseConfig =  staging

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig as FirebaseConfig);
}
const config = firebase.app().options as FirebaseConfig

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics()
// firebase.auth().settings.appVerificationDisabledForTesting = true;
export {
    firebase,
    firestore,
    auth,
    config,
    analytics
}