import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
interface Props {
    handleClose: (is21: boolean) => void
    isOpen: boolean
}

export default function OrderDisclaimer({ handleClose, isOpen }: Props) {

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
            <DialogTitle id="simple-dialog-title">Before You Order!</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    In order to receive alcohol, the person receiving must be at least 21 years of age and prepared to show a valid ID.
          </DialogContentText>
            </DialogContent>
            <DialogContent>
                <DialogContentText>
                    Are you at least 21 years old?
                </DialogContentText>
                <DialogActions>
                    <Button onClick={() => handleClose(true)} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => window.location.href = 'https://www.facebook.com/MotherEarthBrewCo' } color="primary">
                        No
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>

    );
}
