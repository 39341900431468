import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { AddShoppingCart, Remove } from '@material-ui/icons';
import '../App.css';

const useStyles = makeStyles(theme => ({
    formControl: {
        backgroundColor: 'white',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginLeft: '8px',
        marginRight: '8px'
    }
}));

interface Props {
    skus: any[] | undefined
    values: any,
    setValues: (values: any) => void
}

function QuantityForm({ skus, values, setValues }: Props) {
    const classes = useStyles();

    const removeQuantity = () => {
        const cartItem = values;
        const savedSKUId = cartItem && cartItem.skuId
        const selectedSKU = skus?.find((sku) => sku.id === savedSKUId);
        const savedQuantity = ((cartItem && cartItem.quantity) || 0)
        const quantity = savedQuantity > 0 ? savedQuantity - 1 : 0;
        const subTotal = (quantity * selectedSKU.price);
        setValues({
            ...values,
            quantity,
            subTotal
        })
    }

    const addQuantity = () => {
        const cartItem = values;
        const savedSKUId = cartItem && cartItem.skuId
        const selectedSKU = skus?.find((sku) => sku.id === savedSKUId);
        const savedQuantity = ((cartItem && cartItem.quantity) || 0)
        const quantity = savedQuantity + 1
        const subTotal = (quantity * selectedSKU.price);
        setValues({
            ...values,
            quantity,
            subTotal
        })
    }

    if (!values || !values.skuId) {
        return null
    }
    
    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Quantity
    </InputLabel>
            <div style={{
                paddingTop: '6px'
            }}>
                <IconButton onClick={() => removeQuantity()}>
                    <Remove />
                </IconButton>
                <span>{(values && values.quantity) || 0}</span>
                <IconButton onClick={() => addQuantity()}>
                    <AddShoppingCart />
                </IconButton>
            </div>
        </FormControl>

    )
}

export default QuantityForm;
