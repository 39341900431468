// import firebaseConfig from './firebaseConfig'
import {config as firebaseConfig} from './firebase'
const config = {
    ...firebaseConfig,
    app: {
        phoneNumber: '7602592713',
        // apiUrl: `http://localhost:5000/${firebaseConfig.projectId}/us-central1`
        apiUrl: `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`
    },
    maps: {
        libraries: ['places'],
        origin: {
            center: {
                lat: 33.2024482,
                lng: -117.2424044,
            },
            address: '206 Main St, Vista, CA 92084'
        }
    },
    delivery: {
        fee: 500,
        tax: 0.08,
        zipcodes:[92054,
            92056,
            92057,
            92058,
            92003,
            92083,
            92084,
            92069,
            92026,
            92027,
            92025,
            92029,
            92078,
            92081,
            92010,
            92008,
            92011,
            92009,
            92128,
            92074,
            92127,
            92091,
            92067,
            92024,
            92007,
            92075,
            92014,
            92130,
            92129,
            92064]
    }
}

export default config