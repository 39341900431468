import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../App.css';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import { LocalDrink } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  formControl: {
    backgroundColor: 'white',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: '8px',
    marginRight: '8px'
  }
}));

interface Props {
    products: any[] | undefined
    values: any,
    setValues: (values: any) => void
}

function BeerForm({ products, values, setValues }: Props) {
  const classes = useStyles();

  const addBeer = (event: any) => {
    const product = products?.find((product) => product.id === event.target.value)
    if (product) {
      setValues({
        ...values,
        productId: product.id,
        productName: `${product.name} - ${product.caption}`
      })
    }
  }

  const beers = products?.map((product, key) => (
    <MenuItem key={key} value={product.id}>
      {/* <span>{product.name}</span>

      <span>{product.caption}</span> */}
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#fff'}}>
            {product.images && product.images.length > 0 && 
              <img style={{ height: 45, width: 45}} alt={product.name} src={product.images[0]} />
            }
            {(!product.images || product.images.length < 1 )&& 
            <LocalDrink />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={product.name} secondary={product.caption} />
      </ListItem>
      </MenuItem>
  ))
  return (
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Beer
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={(values && values.productId) || ''}
          onChange={addBeer}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="">
            <em>Select a beer</em>
          </MenuItem>
          {beers}
        </Select>
      </FormControl>

  )
}

export default BeerForm;
