import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import { LocalDrink } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { AddShoppingCart, Remove } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { CartItem, CartList } from './OrderSession'
import axios, { AxiosResponse, AxiosError } from 'axios';
import config from './config'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            margin: '8px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        button: {
            marginLeft: '8px',
            marginRight: '8px',
            marginBottom: '16px'
        },
        inline: {
            display: 'inline',
        },
    }),
);

interface Props {
    setCart: (cart: CartList) => void
    cart: CartList
    skus: any[]
    products: any[]
    showTotal?: boolean
}

function Cart({ setCart, cart, skus, showTotal }: Props) {
    const classes = useStyles();

    const addQuantity = (cartItemIndex: number) => {
        const cartItem = cart.items[cartItemIndex];
        const savedSKUId = cartItem && cartItem.skuId
        const selectedSKU = skus?.find((sku) => sku.id === savedSKUId);
        const savedQuantity = ((cartItem && cartItem.quantity) || 0)
        const quantity = savedQuantity + 1
        const subTotal = (quantity * selectedSKU.price);
        cart.items[cartItemIndex] = {
            ...cartItem,
            quantity,
            subTotal
        }

        const cartTotal = cart.items.reduce((accum: number, item: CartItem) => {
            const { subTotal = 0 } = item
            accum = accum + subTotal;
            return accum;
        }, 0)

        setCart({
            ...cart,
            deliveryFee: cartTotal >= 5000 ? 0 : config.delivery.fee
        })
    }

    const removeQuantity = (cartItemIndex: number) => {
        const cartItem = cart.items[cartItemIndex];
        const savedSKUId = cartItem && cartItem.skuId
        const selectedSKU = skus?.find((sku) => sku.id === savedSKUId);
        const savedQuantity = ((cartItem && cartItem.quantity) || 0)
        const quantity = savedQuantity > 0 ? savedQuantity - 1 : 0;
        const subTotal = (quantity * selectedSKU.price);
        cart.items[cartItemIndex] = {
            ...cartItem,
            quantity,
            subTotal
        }
        
        const cartTotal = cart.items.reduce((accum: number, item: CartItem) => {
            const { subTotal = 0 } = item
            accum = accum + subTotal;
            return accum;
        }, 0)

        setCart({
            ...cart,
            deliveryFee: cartTotal >= 5000 ? 0 : config.delivery.fee
        })

    }

    const removeCartItem = (index: number) => {
        if (cart.items) {
            const { items } = cart;
            items.splice(index, 1)
            const cartTotal = items.reduce((accum: number, item: CartItem) => {
                const { subTotal = 0 } = item
                accum = accum + subTotal;
                return accum;
            }, 0)

            setCart({
                ...cart,
                ...items,
                deliveryFee: cartTotal >= 5000 ? 0 : config.delivery.fee
            })
        }
    }

    const cartItems = cart.items.map((item: CartItem, index: number) => (
        <ListItem key={index}>
            <ListItemIcon>
                <LocalDrink />
            </ListItemIcon>
            <ListItemText
                id="switch-list-label-bluetooth"
                primary={item.productName}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            {item.skuName}
                        </Typography>
                        <Button onClick={() => removeCartItem(index)}>Remove</Button>
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <div style={{
                    paddingTop: '6px'
                }}>
                    <IconButton onClick={() => removeQuantity(index)}>
                        <Remove />
                    </IconButton>
                    <span>{(item.quantity) || 0}</span>
                    <IconButton onClick={() => addQuantity(index)}>
                        <AddShoppingCart />
                    </IconButton>
                </div>
            </ListItemSecondaryAction>
        </ListItem>
    ))


    if (cart.items.length < 1) {
        return null
    }

    const cartSubTotal = cart.items.reduce((accum: number, item: CartItem) => {
        const { subTotal = 0 } = item
        accum = accum + subTotal;
        return accum;
    }, 0);

    const crvTotal = cart.items.reduce((accum: number, item: CartItem) => {
        const { crv = 0, quantity = 0 } = item
        accum = accum + (crv * quantity);
        return accum;
    }, 0);   
    
    const depositTotal = cart.items.reduce((accum: number, item: CartItem) => {
        const { deposit = 0, quantity = 0 } = item
        accum = accum + (deposit * quantity);
        return accum;
    }, 0);

    const cartTax = cartSubTotal * cart.taxRate;
    const cartTaxFormatted = Number((cartTax) / 100).toFixed(2)
    const cartTotalFormatted = Number((cartSubTotal + cart.deliveryFee + cartTax + crvTotal) / 100).toFixed(2)
    return (
        <div className="content">
            <List subheader={<ListSubheader>Cart</ListSubheader>} className={classes.root}>
                {cartItems}
                {showTotal && <Divider />}
                {showTotal &&
                    <List component="nav" aria-label="secondary mailbox folders">
                        <ListItem>
                            Sub-total: ${cartSubTotal / 100}
                        </ListItem>
                        <ListItem>
                            CRV: ${Math.abs(crvTotal / 100).toFixed(2)}
                        </ListItem>    
                        { depositTotal > 0 && <ListItem>
                            Keg Deposit: ${Math.abs(depositTotal / 100).toFixed(2)}
                        </ListItem>}
                        <ListItem>
                            Tax: ${cartTaxFormatted}
                        </ListItem>
                        <ListItem>
                            Delivery Fee (waived for orders over $50): ${cart.deliveryFee / 100}
                        </ListItem>
                        <ListItem>
                            Total: ${cartTotalFormatted}
                        </ListItem>
                    </List>
                }
            </List>
        </div>
    );
}

export default Cart