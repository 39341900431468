import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Cart from './Cart'
import BeerForm from './CheckoutForm/Beer'
import FormatForm from './CheckoutForm/Format'
import './App.css';
import QuantityForm from './CheckoutForm/Quantity';
import SubtotalForm from './CheckoutForm/Subtotal';
import { CartItem, CartList } from './OrderSession'
import { getProducts, getSKUs } from './service'
import Error from './Error'
import config from './config';
const useStyles = makeStyles(theme => ({
  formControl: {
    backgroundColor: 'white',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: '8px',
    marginRight: '8px'
  }
}));

interface Props {
  canProceed?: () => void
  cart: CartList
  products: any[]
  skus: any[]
  setCart: (cart: CartList) => void
}

function CheckoutForm({ products, skus, canProceed, cart, setCart }: Props) {
  const classes = useStyles();
  const [values, setValues] = useState<CartItem>()

  // if (error) {
  //   return (
  //     <div className="content">
  //       <Error message={error} />
  //     </div>
  //   )
  // }

  // if (!loaded) {
  //   return (
  //     <div className="content">
  //       <div className="loading">Loading</div>
  //     </div>
  //   )
  // }
  if (cart.items.length > 0) {
    canProceed && canProceed();
  }

  const addToCart = () => {
    if (values) {
      const { items } = cart
      items.push(values)
      const cartTotal = items.reduce((accum: number, item: CartItem) => {
        const {subTotal = 0} = item
        accum = accum + subTotal;
        return accum;
      }, 0)
      
      setCart({
        ...cart,
        ...items,
        deliveryFee: cartTotal >= 5000 ? 0 : config.delivery.fee
      })

      setValues(undefined)
    }
  }

  return (
    <div className="content">
      <p className="checkoutHeader">Load Up Your Cart</p>
      <BeerForm products={products} values={values} setValues={setValues} />
      <FormatForm skus={skus} values={values} setValues={setValues} />
      <QuantityForm skus={skus} values={values} setValues={setValues} />
      <SubtotalForm values={values} />

      {values && values.skuId && values.quantity &&
        <Button variant="contained" className={classes.button} color="primary" disableElevation onClick={() => addToCart()}>
          Add To Cart
      </Button>}
      <Cart
        setCart={setCart}
        cart={cart}
        products={products}
        skus={skus}
      />
    </div>
  )
}

export default CheckoutForm;
