import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Cart from './Cart'
import config from './config'
import './App.css';
import { CartList } from './OrderSession';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    textField: {
      justifyContent: 'stretch',

      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 350
    },
  }),
);

interface Props {
  cart: CartList
  setCart: (cart: CartList) => void
  products: any[]
  skus: any[]
  canProceed: () => void
}
function Checkout({ cart, setCart, products, skus, canProceed }: Props) {
  const classes = useStyles();
  canProceed();
  const setInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const instructions = (event.target as HTMLInputElement).value
    setCart({
      ...cart,
      instructions
    })
  }
  
  return (
    <div className="content" style={{backgroundColor: 'white'}}>
      <TextField
        id="outlined-full-width"
        label="Special Instructions"
        multiline
        rowsMax="4"
        style={{ margin: 8 }}
        placeholder="Anything we need to know? (e.g. gate codes, etc...)"
        className={classes.textField}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        onChange={setInstructions}
      />
      <Cart
        setCart={setCart}
        cart={cart}
        products={products}
        skus={skus}
        showTotal
      />
    </div>

  );
}

export default Checkout;
