import ReactPixel from 'react-facebook-pixel'
import {analytics} from './firebase'
import { CartItem } from './OrderSession'

export const trackPurchase = () => {
    try {
        const pendingOrder = JSON.parse(localStorage.getItem('pendingOrder') || '')
        ReactPixel.track('Purchase', pendingOrder)
        analytics.logEvent('purchase', pendingOrder)
        localStorage.removeItem('pendingOrder')
      } catch(err) {
          console.log(err)
      }
}

// const addToCart = (values: CartItem, sku: any) => {
//     ReactPixel.track('AddToCart', {
//         content_name: values.productName,
//         content_type: getTypeFromSKU(sku.id),
//         content_id: sku.id,
//         value: sku.price,
//         currency: 'USD'
//     })
// }

export const initiateCheckout = (items: CartItem[]) => {
    const checkoutObj = items.reduce((accum, item: CartItem) => {
        const { subTotal = 0 } = item;
        accum.value = accum.value + subTotal;
        accum.content_ids.push(item.skuId || '')
        accum.contents.push({
          id: item.skuId || '',
          quantity: item.quantity || 0
        })
        accum.num_items =+ 1;
        return accum
      }, {
        contents: [],
        content_ids: [],
        value: 0,
        num_items: 0,
        currency: 'USD'
      } as any);
      localStorage.setItem('pendingOrder', JSON.stringify(checkoutObj))
      ReactPixel.track('InitateCheckout', checkoutObj)
      analytics.logEvent('begin_checkout', checkoutObj)
}