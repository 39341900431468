import { auth } from './firebase'
import config from './config'
import axios, { AxiosResponse } from 'axios';
import { CartItem, CartList } from './OrderSession';

export const getSession = (token: string) =>
    auth.signInWithCustomToken(token).then((userCred: firebase.auth.UserCredential) => {
        return userCred && userCred.user && userCred.user.uid
    })

export const getProducts = (): Promise<any[]> => {
    return axios.get(config.app.apiUrl + '/stripe/product-list')
        .then((response: AxiosResponse<any[]>) => {
            return response.data
        })
}

export const getSKUs = (): Promise<any[]> => {
    return axios.get(config.app.apiUrl + '/stripe/sku-list')
        .then((response: AxiosResponse<any[]>) => {
            return response.data
        })
}

export const getCheckoutSession = (sessionToken: string, cart: CartList): Promise<{ sessionId: string, publicKey: string }> => {
    // const lineItems = items.map((item) => ({
    //     amount: item.skuPrice,
    //     name: `${item.productName} - ${item.skuName}`,
    //     currency: 'usd',
    //     quantity: item.quantity
    // }))
    return axios.post(config.app.apiUrl + '/stripe/create-checkout-session', {
        sessionToken,
        cart
    })
        .then((response: AxiosResponse<{ sessionId: string, publicKey: string }>) => {
            return response.data
        })
}

export const phoneNumber = config.app.phoneNumber
export function formatPhoneNumber(phoneNumberString: string ) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}